import BankService from '@/services/modules/BankService';
import router from '@/router';

const bankService = new BankService();

const url = 'super-admin/bank';

export default {
  namespaced: true,
  state: {
    banks: [],
    formPayload: {
      name: '',
      description: '',
      logo: '',
    },
  },
  getters: {},
  mutations: {
    SET_BANKS(state, banks) {
      state.banks = banks;
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload;
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await bankService.getData(url, params);
      if (response) {
        commit('SET_BANKS', response.data.data);
      }
    },
    async showData({ commit }, { id, params }) {
      const response = await bankService.showData(url, id, params);
      if (response) {
        commit('SET_BANKS', response.data.data);
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await bankService.postData(`${url}/${id}`, payload);

      return response;
    },
    async deleteData({ commit }, { id }) {
      const response = await bankService.deleteData(url, id);

      return response;
    },
  },
};
