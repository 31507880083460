import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import users from './modules/users';
import bank from './modules/bank';
import brand from './modules/brand';
import item from './modules/item';
import warehouse from './modules/warehouse';
import uom from './modules/uom';
import bankAccounts from './modules/bank_accounts';
import cabang from './modules/cabang';
import subscription from './modules/subscription';
import toko from './modules/toko';
import produk from './modules/produk';
import career from './modules/career';
import contact_us from './modules/contact_us';
import supplier from './modules/supplier';
import customer from './modules/customer';
import merchant from './modules/merchant';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    users,
    bank,
    brand,
    item,
    warehouse,
    uom,
    bankAccounts,
    cabang,
    subscription,
    toko,
    produk,
    career,
    contact_us,
    supplier,
    customer,
    merchant,
    app,
    appConfig,
    verticalMenu,
  },
  strict: process.env.DEV,
});
