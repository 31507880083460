import BaseService from '@/services/BaseService';
import router from '@/router';

const baseService = new BaseService();

const url = 'super-admin/job-application';

export default {
  namespaced: true,
  state: {
    careers: [],
    formPayload: {
      name: '',
      description: '',
      logo: '',
    },
  },
  getters: {},
  mutations: {
    SET_CAREERS(state, careers) {
      state.careers = careers;
    },
    SET_FORM_PAYLOAD(state, formPayload) {
      state.formPayload = formPayload;
    },
  },
  actions: {
    async getData({ commit }, { params }) {
      const response = await baseService.getData(url, params);
      if (response) {
        commit('SET_CAREERS', response.data.data);
      }
    },
    async showData({ commit }, { id, params }) {
      const response = await baseService.showData(url, id, params);
      if (response) {
        commit('SET_CAREERS', response.data);
      }
    },
    async postData({ commit }, { id, payload }) {
      const response = await baseService.postData(`${url}/${id}`, payload);

      return response;
    },
    async deleteData({ commit }, { id }) {
      const response = await baseService.deleteData(url, id);

      return response;
    },
  },
};
