export default [
  {
    path: '/toko',
    name: 'toko.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/toko/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Toko',
      breadcrumb: [
        {
          text: 'Toko',
          active: true,
        },
      ],
    },
  },
  {
    path: '/cabang',
    name: 'cabang.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/cabang/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Cabang',
      breadcrumb: [
        {
          text: 'Cabang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/subscription',
    name: 'subscription.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/subscription/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Subscription',
      breadcrumb: [
        {
          text: 'Subscription',
          active: true,
        },
      ],
    },
  },
  {
    path: '/gudang',
    name: 'gudang.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/gudang/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Gudang',
      breadcrumb: [
        {
          text: 'Gudang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customer',
    name: 'customer.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/customer/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Customer',
      breadcrumb: [
        {
          text: 'Customer',
          active: true,
        },
      ],
    },
  },
  {
    path: '/users',
    name: 'users.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/users/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Users',
      breadcrumb: [
        {
          text: 'Users',
          active: true,
        },
      ],
    },
  },
  {
    path: '/supplier',
    name: 'supplier.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/supplier/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Supplier',
      breadcrumb: [
        {
          text: 'Supplier',
          active: true,
        },
      ],
    },
  },
  {
    path: '/career',
    name: 'career.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/career/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Career',
      breadcrumb: [
        {
          text: 'Career',
          active: true,
        },
      ],
    },
  },
  {
    path: '/contact_us',
    name: 'contact_us.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/contact_us/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Contact Us',
      breadcrumb: [
        {
          text: 'Contact Us',
          active: true,
        },
      ],
    },
  },

  // MASTER
  {
    path: '/master/gudang',
    name: 'master-gudang.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/gudang/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Master Gudang',
      breadcrumb: [
        {
          text: 'Master Gudang',
          active: true,
        },
      ],
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/admin',
    name: 'master-admin.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/admin-user/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Master Admin',
      breadcrumb: [
        {
          text: 'Master Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/cabang',
    name: 'master-cabang.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/cabang/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
      pageTitle: 'Master Cabang',
      breadcrumb: [
        {
          text: 'Master Cabang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/brand',
    name: 'master-brand.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/master/brand/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
      pageTitle: 'Master Brand',
      breadcrumb: [
        {
          text: 'Master Brand',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/barang',
    name: 'master-barang.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/barang/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
      pageTitle: 'Master Barang',
      breadcrumb: [
        {
          text: 'Master Barang',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/satuan',
    name: 'master-satuan.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/satuan/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
      pageTitle: 'Master Satuan',
      breadcrumb: [
        {
          text: 'Master Satuan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/bank',
    name: 'master-bank.index',
    component: () => import('@/views/_PREVIOUS_FEATURE/master/bank/index.vue'),
    meta: {
      auth: true,
      pageTitle: 'Master Bank',
      breadcrumb: [
        {
          text: 'Master Bank',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/produk',
    name: 'master.product.index',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/product/index.vue'),
    meta: {
      auth: true,
      navActiveLink: 'master.product.index',
      pageTitle: 'Master Produk',
      breadcrumb: [
        {
          text: 'Master Produk',
          active: true,
        },
      ],
    },
  },
  {
    path: '/master/produk/bulk-link',
    name: 'master.product.bulk-link',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/product/bulk-link.vue'),
    meta: {
      auth: true,
      layout: 'full',
      navActiveLink: 'master.product.index',
    },
  },
  {
    path: '/master/produk/:id',
    name: 'master.product.show',
    component: () =>
      import('@/views/_PREVIOUS_FEATURE/master/product/show.vue'),
    meta: {
      auth: true,
      layout: 'full',
      navActiveLink: 'master.product.index',
    },
  },
];
