import Vue from 'vue'

// axios
import axios from 'axios'
import { cacheAdapterEnhancer, throttleAdapterEnhancer } from 'axios-extensions';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const axiosIns = axios.create({
  baseURL: process.env.API_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
	adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false, cacheFlag: 'useCache'})
})

Vue.prototype.$http = axiosIns

axiosIns.interceptors.response.use(response => {
  return response;
}, error => {
    if (error.response.status === 401) {
      Vue.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title: 'Login Gagal!',
          icon: 'DangerIcon',
          variant: 'danger',
          text:
            (error.response.data &&
              error.response.data.meta &&
              error.response.data.meta.messages.join(', ')) ||
            '',
        },
      });
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userData');
      localStorage.removeItem('permissions');
      if (window.location.pathname !== '/login') {
        window.location.href = `${window.location.origin}/login`;
      }
    }

    if (error.response.status === 403) {
      window.location.href = window.location.origin + "/error-403";
    }

    return Promise.reject(error);
});

export default axiosIns
